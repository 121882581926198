<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <Header></Header>

        <div class="text-start mn-profile">
          <span class="text-root mini-h">คำขอใช้บริการ</span>
        </div>
        <AppealTitles></AppealTitles>
        <Appeals></Appeals>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject } from "vue";
import Header from "@/components/Header";
import AppealTitles from "@/components/Appeal/AppealTitle";
import Appeals from "@/components/Appeal/Appeal";


export default defineComponent({
  name: "Profile",
  components: {
    Header,
    AppealTitles,
    Appeals,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });
  },
});
</script>

<style scoped></style>
